<template>
    <div class="box_2_no">
        <div class="box_21">
            <div class="b21_title">Request Code and Application</div>
            <div>This plugin is <span style="color: red;font-weight: bold;">FREE</span> to use. lf you're interested
                in using it, please leave your contact information,and someone will reach out to you shortly.</div>
            <div style="display: flex;justify-content: space-between;width: 100%;"><el-input class="elInput"
                    v-model="userName" placeholder="First Name *"></el-input>
                <el-input v-model="userPhone" class="elInput" placeholder="Tel *"></el-input>
            </div>
            <div style="width: 100%;margin:20px 0px 40px 0px;">
                <el-input v-model="workEmail" style="width: 100%;" placeholder="Work Email *"></el-input>
            </div>
            <div class="se24"><el-checkbox v-model="isAllowSend">Send me emails about Contrimetric
                    products and updates</el-checkbox>
                <p style="margin: 5px 0px 5px 25px;color:#000000">For complete details please visit
                    <span style="text-decoration: underline;cursor: pointer;"
                        @click="tipWorks">https://www.contrimetric.com/works</span>
                </p>
                <p style="margin: 5px 0px 10px 25px;color:#000000"> in touch at
                    contact@contrimetric.com
                </p>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;"><el-button
                    @click="submit">Request</el-button></div>
        </div>
    </div>
</template>

<script>
import $http from "@/request/http";

export default {
    data() {
        return {
            userName: '',
            userPhone: '',
            workEmail: '',
            isAllowSend: false
        }
    },
    methods: {
        submit() {
            let paramap = {
                userName: this.userName,
                userPhone: this.userPhone,
                userEmail: this.workEmail,
                isAllowSend: this.isAllowSend,
            }

            if (this.userName == '' || this.userPhone == '' || this.workEmail == '') {
                return this.$message.warning("Please complete the form")
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            $http.postFhl("/journal/inserJournalForm", paramap, true).then(res => {
                if (res.code == 200) {
                    this.$message.success("Submit successfully")
                    loading.close()
                }
            }).catch(err => {
                console.log(err);
            });
        },
        tipWorks() {
            this.$router.push('/works')
        },
    }
}
</script>

<style lang="scss">
.box_2_no {
    width: 100%;
    /* height: 500px; */
    background-image: url(../../../assets/images/no_data_form.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box_21 {
    width: 40%;
    padding: 80px 0px 60px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .se24 {
        margin-left: 30px;
    }
}

.box_21>div:nth-child(1) {
    font-family: MicrosoftTaiLe-Bold;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 26px;
    color: #000000;
    text-align: center;
}

.elInput {
    width: 48%;
}

.box_21>div:nth-child(2) {
    margin: 30px 0px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 19px;
    letter-spacing: 0px;
    color: #001111;
}

::v-deep .box_21 .el-input__inner {
    background-color: transparent;
    border: 1px solid black;
}

::v-deep .box_21 .el-checkbox__inner {
    background-color: transparent;
    border: 1px solid black;

}

::v-deep .box_21 .el-checkbox {
    color: #000000;
}

.box_21 .el-button {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    background-color: #1da6b8;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 20px;
    padding: 19px 60px 18px 60px;
}

@media (max-width: 768px) {
    .box_2_no {
        .box_21 {
            width: 100%;
            padding: 20px;

            .b21_title{
                font-size: 24px;
            }

            .se24 {
                margin-left: 0;

                .el-checkbox__label {
                    white-space: break-spaces;
                }
            }

            .el-button {
                padding: 14px 50px;
                font-size: 18px;
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
    }
}
</style>